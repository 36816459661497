import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Grid from '@material-ui/core/Grid';
import loadable from "@loadable/component"
import { CircularProgress } from "@material-ui/core"
// import Helmet from "react-helmet"

import gql from "graphql-tag"
import { Query } from "react-apollo"

import { getSearchParams } from "gatsby-query-params"

const GetPost = loadable(() => import("../components/getpost"))

// const APOLLO_QUERY = gql`

// query BlogPost($id: Int!) {

//  postBy(postId: $id) {
//     title
//     featuredImage {
//       mediaItemUrl
//     }
//     content
//     date
//     author {
//       firstName
//       lastName
//     }
//  }
//  posts(last: 5) {
//     edges {
//       node {
//         title
//         postId
//       }
//     }
//   }
//   categories {
//     nodes {
//       name
//       count
//     }
//   }
// }
// `

const APOLLO_QUERY = gql`
  query BlogPost($id: Int!) {
    postBy(postId: $id) {
      title
      content
      date
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      author {
        node {
          lastName
          firstName
        }
      }
    }
    posts(last: 5) {
      edges {
        node {
          title
          postId
        }
      }
    }
    categories {
      nodes {
        name
        count
      }
    }
  }
`

let content = (
  <div style={{ textAlign: "center", padding: "30vh 0 50vh 0" }}>
    <CircularProgress color="inherit" />
  </div>
)

const BlogPost = () => {
  const searchParams = getSearchParams()

  return (
    <Layout>
      <SEO title="Blog Post" />

      <Query query={APOLLO_QUERY} variables={{ id: Number(searchParams.id) }}>
        {({ data }) => {
          if (data) {
            return <GetPost data={data} />
          }

          return content
        }}
      </Query>
    </Layout>
  )
}

export default BlogPost
